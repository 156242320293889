import React from 'react';
import PropTypes from 'prop-types';

import { Cover, GridResults, Image, Layout, Link, SectionFAQs, SEO } from 'components';
import { routes } from 'utils/routes';
import reviewSelfies from 'utils/config/reviewSelfies';

import content from './faqs.yml';

const FAQPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <Cover
      title='Get answers to your Qs'
      image={{ url: 'pages/faq_cover.jpg', alt: 'Teeth straightening FAQS' }}
      button={{ label: 'Free e-consultation', ...routes.getStarted }}
      overlap
    />

    <SectionFAQs />

    <h3 className='section-title mt-90 mb-50'>The only aligner service to guarantee results, with no hidden costs.</h3>

    <div className='flex-center'>
      <div className='flex-center flex-col mr-40'>
        <Image url='icons/icon_blog.svg' alt='Teeth straightening blog' />
        <p className='mt-20 mb-20 t-center'><b>Find tons of content<br/>on our blog</b></p>
        <Link to={routes.blog.path} {...routes.blog} className='button outline'>Visit the blog</Link>
      </div>
      <div className='flex-center flex-col ml-40'>
        <Image url='icons/icon_answer.svg' alt='Can’t find your answer?' />
        <p className='mt-20 mb-20 t-center'><b>Can't find<br/>your answer?</b></p>
        <Link to={routes.contacts.path} {...routes.contacts} className='button outline'>Contact us</Link>
      </div>
    </div>

    <h3 className='section-title mt-90 mb-50'>#MySmileDelivered reviews</h3>

    <GridResults link={{ label: 'View more results' }} pictures={reviewSelfies} trustpilot slide hasAction className='mb-90' />
  </Layout>
);

FAQPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default FAQPage;
